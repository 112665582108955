<template>
  <div class="content">
    <ApplicationNannyInputPerks
      v-model:perks-advantages="applicationNanny.perks"
      v-model:perks-languages="applicationNanny.details.languages"
      v-model:is-valid="isValid"
    />
    <ButtonPrimaryNext
      :to="{ name: 'ApplicationNannyCreateAboutMe' }"
      :isDisabled="!isValid"
    />
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useApplicationNannyStore } from "@/stores/applicationNanny";

import ApplicationNannyInputPerks from "@/components/Application/Nanny/ApplicationNannyInputPerks.vue";
import ButtonPrimaryNext from "@/components/UI/ButtonPrimaryNext";

export default {
  components: {
    ApplicationNannyInputPerks,
    ButtonPrimaryNext,
  },

  setup() {
    const applicationNanny = useApplicationNannyStore();

    const isValid = ref(false);

    return {
      applicationNanny,
      isValid,
    };
  },
};
</script>

<style scoped>
.content {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 80px;
}

@media (max-width: 1200px) {
}
</style>
