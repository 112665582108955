<template>
  <div class="container">
    <BaseH1 :html="'Zaznacz,<br />co Ci odpowiada'" />

    <div class="wrapper-checkboxes">
      <BaseH2 :text="'Twoje zalety'" />
      <InputCheckboxGroupChips
        v-model="application.perks.advantages"
        :name="'advantages'"
        :options="optionsAdvantages"
      />
    </div>

    <div class="wrapper-checkboxes">
      <BaseH2 :text="'Znajomość języków'" />
      <LanguagesInputCheckboxGroupSticked
        v-model="application.perks.languages"
      />
    </div>
  </div>
</template>

<script>
import { computed, reactive, toRefs } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import BaseH2 from "@/components/UI/Base/BaseH2.vue";
import InputCheckboxGroupChips from "@/components/UI/Base/InputCheckboxGroupChips.vue";
import LanguagesInputCheckboxGroupSticked from "@/components/LanguagesInputCheckboxGroupSticked.vue";

export default {
  components: {
    BaseH1,
    BaseH2,
    InputCheckboxGroupChips,
    LanguagesInputCheckboxGroupSticked,
  },

  props: {
    perksAdvantages: Array,
    perksLanguages: Array,
  },

  emits: ["update:perksAdvantages", "update:perksLanguages", "update:isValid"],

  setup(props, { emit }) {
    const application = reactive({
      perks: {
        advantages: props.perksAdvantages,
        languages: props.perksLanguages,
      },
    });

    watch(
      () => application.perks.advantages,
      (newAdv) => emit("update:perksAdvantages", newAdv)
    );
    watch(
      () => application.perks.languages,
      (newLang) => emit("update:perksLanguages", newLang)
    );
    watch(Object.values(toRefs(props)), () => {
      application.perks.advantages = props.perksAdvantages;
      application.perks.languages = props.perksLanguages;
    });

    const isValid = computed(() => {
      return !!application.perks.languages.length;
    });

    watch(isValid, (newIsValid) => emit("update:isValid", newIsValid), {
      immediate: true,
    });

    const optionsAdvantages = [
      {
        label: "posiadam prawo jazdy",
        value: "driver_license",
      },
      {
        label: "gotowa do podroży",
        value: "ready_to_travel",
      },
      {
        label: "niepaląca",
        value: "non_smoking",
      },
      {
        label: "posiadam własny samochód",
        value: "own_car",
      },
    ];

    return {
      application,
      isValid,
      optionsAdvantages,
    };
  },
};
</script>

<style scoped>
.container {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 80px;
}
.wrapper-checkboxes {
  width: 560px;

  display: flex;
  flex-flow: column;
  align-items: flex-start;

  row-gap: 15px;
}

@media (max-width: 1200px) {
  .container {
    width: 100%;

    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 60px;
  }
  .wrapper-checkboxes {
    width: 100%;

    display: flex;
    flex-flow: column;
    align-items: flex-start;

    row-gap: 15px;
  }
}
</style>
