<template>
  <InputCheckboxGroupSticked
    :name="'languages'"
    :options="options"
    :columns="3"
  />
</template>

<script>
import {} from "vue";

export default {
  setup() {
    const options = [
      {
        label: "English",
        value: "en",
      },
      {
        label: "Italiano",
        value: "it",
      },
      {
        label: "Français",
        value: "fr",
      },
      {
        label: "Deutsch",
        value: "de",
      },
      {
        label: "Español",
        value: "es",
      },
      {
        label: "Русский",
        value: "ru",
      },
      {
        label: "Polski",
        value: "pl",
      },
      {
        label: "漢語",
        value: "ch",
      },
      {
        label: "Українська",
        value: "ua",
      },
    ];
    return {
      options,
    };
  },
};
</script>

<style scoped></style>
